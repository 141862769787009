import mixpanelActions from "../mixpanel";
import get from "lodash/get";
import isUndefined from "lodash/isUndefined";

export function trackCheckout(trackLabel, data) {
  mixpanelActions.track(trackLabel, {
    affiliation: "Dulux Pilot Programme",
    subtotal: get(data, "subtotalPriceV2.amount"),
    currency: get(data, "subtotalPriceV2.currencyCode"),
    products: get(data, "lineItems.edges").map((item) => {
      return getProductTrackingData(get(item, 'node.variant.product'));
    }),
  });
}

export function trackProductFilter(listId, filterData, sortData, products) {

  if(! isUndefined(products)) {
    let mappedProducts = products.map((product, index) => {
      return getProductTrackingData(product, index);
    });

    mixpanelActions.track("Product List Filtered",
      {
        list_id: listId,
        filters: filterData,
        sorts: sortData,
        products: mappedProducts,
      }
    );
  }
}

export function trackProduct(trackLabel, product, index = 0, quantity = 1) {
    mixpanelActions.track(trackLabel, getProductTrackingData(product, index, quantity));
}

function getProductTrackingData(product, index = 0, quantity = 1) {
  return {
    product_id: get(product, 'id'),
    sku: get(product, 'variants.edges.0.node.sku'),
    name: get(product, 'title'),
    currency: get(product, 'priceRange.minVariantPrice.currencyCode'),
    price: get(product, "priceRange.minVariantPrice.amount"),
    category: get(product, 'productType'),
    variant: get(product, 'variants.edges.0.node.title'),
    url: get(product, 'handle'),
    image_url: get(product, 'images.edges.0.node.transformedSrc', ''),
    quantity: quantity,
    position: index + 1,
  }
}
