import {actions} from '../store';
import {shopify} from './api';
import {PRODUCTS_QUERY} from "../queries/products";

export function getFirstPage(variables) {
  return async dispatch => {
    try {
      dispatch(actions.products.getFirstPageRequest());

      const {data} = await shopify.query({
        query: PRODUCTS_QUERY,
        variables
      });

      dispatch(actions.products.getFirstPageSuccess({data: data.products}));
    } catch (error) {
      dispatch(actions.products.getFirstPageFailure({error}));
    }
  };
}

export function getNextPage(variables) {
  return async dispatch => {
    try {
      dispatch(actions.products.getNextPageRequest());

      const {data} = await shopify.query({
        query: PRODUCTS_QUERY,
        variables
      });

      dispatch(actions.products.getNextPageSuccess({data: data.products}));
    } catch (error) {
      dispatch(actions.products.getNextPageFailure({error}));
    }
  };
}
