import { gql } from "apollo-boost";

export const PRODUCT_FRAGMENT = gql`
  fragment product on Product {
    id
    title
    handle
    productType
    description
    descriptionHtml
    images(first: 10) {
      edges {
        node {
          altText
          transformedSrc
        }
      }
    }
    options {
      id
      name
      values
    }
    priceRange {
      minVariantPrice {
        amount
        currencyCode
      }
      maxVariantPrice {
        amount
        currencyCode
      }
    }
    variants(first: 250) {
      edges {
        node {
          id
          title
          sku
          priceV2 {
            amount
          }
          selectedOptions {
            name
            value
          }
          bundleData: metafield(namespace: "bundles_app" key: "content") {
            id
            key
            value
          }
        }
      }
    }
  }
`;

export const QUERY_PRODUCT_BY_HANDLE = gql`
  ${PRODUCT_FRAGMENT}
  query product($handle: String!) {
    productByHandle(handle: $handle) {
      ...product
    }
  }
`;
