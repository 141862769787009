import {actions} from '../store';
import {shopify} from './api';
import {QUERY_PRODUCT_BY_HANDLE} from "../queries/product";

export function get(variables) {
  return async dispatch => {
    try {
      dispatch(actions.product.getRequest());

      const {data} = await shopify.query({
        query: QUERY_PRODUCT_BY_HANDLE,
        variables
      });

      dispatch(actions.product.getSuccess({data: data.productByHandle}));
    } catch (error) {
      dispatch(actions.product.getFailure({error}));
    }
  };
}
