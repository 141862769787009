import { gql } from "apollo-boost";

const PRODUCTS_FRAGMENT = gql`
  fragment products on ProductConnection {
    edges {
      node {
        id
        title
        handle
        description
        createdAt
        images(first: 1) {
          edges {
            node {
              transformedSrc
              altText
            }
          }
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        productType
        variants(first: 250) {
          edges {
            node {
              id
              title
              sku
              selectedOptions {
                name
                value
              }
            }
          }
        }
        collections(first: 20) {
          edges {
            node {
              title
            }
          }
        }
      }
      cursor
    }
    pageInfo {
      hasNextPage
    }
  }
`;

export const PRODUCTS_QUERY = gql`
  ${PRODUCTS_FRAGMENT}
  query products(
    $cursor: String
    $query: String!
    $sortKey: ProductSortKeys!
    $reverse: Boolean!
  ) {
    products(
      first: 250
      after: $cursor
      query: $query
      sortKey: $sortKey
      reverse: $reverse
    ) {
      ...products
    }
  }
`;

export const QUERY_ALL_PRODUCT_HANDLES = gql`
  query {
    products(first: 250) {
      edges {
        node {
          handle
        }
      }
    }
  }
`;
