/**
 * Require the Contentful javascript
 * client.
 */
const contentful = require('contentful');

/**
 * Export the createClient function with
 * the default config
 */
export default contentful.createClient({
  space: process.env.CONTENTFUL.SPACE_ID,
  accessToken: process.env.CONTENTFUL.CDA_ACCESS_TOKEN,
  environment: process.env.CONTENTFUL.ENVIRONMENT
});
